import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Note from '../components/Note';
import Code from '../components/code';
export const _frontmatter = {
  "title": "CSS Grid Track Options",
  "date": "2019-11-28",
  "promo": "grids",
  "description": "CSS Grid row/column/gap options and when to use them",
  "color": "#433786"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`CSS Grid is hands down the best way to build the majority of layouts today. But how should you define your Grid's tracks (rows & columns)? What about gaps? You've got a `}<em parentName="p">{`lot`}</em>{` of options and units to choose from. When should you use what? Let's take a look!`}</p>
    <Note mdxType="Note">NOTE: You can edit all of these code examples live to get a feel for each option.</Note>
    <Note mdxType="Note">The <a href="https://gridcritters.com">Grid Critters</a> mastery game also gives you a ton of deliberate practice with all of these.</Note>
    <p>{`Jump to:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#fr"
        }}>{`fr`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#percent"
        }}>{`%`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#px"
        }}>{`px`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ch"
        }}>{`ch`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ex"
        }}>{`ex`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#auto"
        }}>{`auto`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#none"
        }}>{`none`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#min-content"
        }}>{`min-content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#max-content"
        }}>{`max-content`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#rem"
        }}>{`rem`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#em"
        }}>{`em`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#vh-vw-vmin-vmax-viewport-units"
        }}>{`vh, vw, vmin, vmax (viewport units)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#minmaxmin-max"
        }}>{`minmax(min, max)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#repeat"
        }}>{`repeat`}</a></li>
    </ul>
    <h2>{`fr`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`fr`}</inlineCode>{` unit is the one of the `}<a parentName="p" {...{
        "href": "/post/shiny-new-grid-tools/"
      }}>{`new goodies`}</a>{` that came with CSS Grid and is my absolute favorite. You can think of `}<inlineCode parentName="p">{`fr`}</inlineCode>{` as `}<em parentName="p">{`parts`}</em>{` like in baking. One part flour, three parts sugar. This awesome new unit make responsive layouts easy. `}</p>
    <p>{`Here's a grid with three columns, where the middle column is twice as big as the outer columns:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
  .item {
    height: 300px;
  }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>  
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`Play around with those `}<inlineCode parentName="p">{`fr`}</inlineCode>{` ratios above and see how the columns change!`}</p>
    <p>{`All of the options in this post work for both columns `}<em parentName="p">{`and`}</em>{` rows, `}<inlineCode parentName="p">{`fr`}</inlineCode>{` included. Here's an grid that uses `}<inlineCode parentName="p">{`fr`}</inlineCode>{` based rows:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 400px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-rows: 1fr 2fr 3fr;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>  
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <p><inlineCode parentName="p">{`fr`}</inlineCode>{` tracks are fractions of any `}<em parentName="p">{`remaining space`}</em>{` after fixed units like `}<inlineCode parentName="p">{`px`}</inlineCode>{` and after any gaps have had their say.`}</p>
    <p>{`In this grid the `}<inlineCode parentName="p">{`1fr`}</inlineCode>{` row's height will be anything left after the `}<inlineCode parentName="p">{`200px`}</inlineCode>{` row and the `}<inlineCode parentName="p">{`20px`}</inlineCode>{` gap:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 400px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-rows: 200px 1fr;
    grid-row-gap: 20px;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div> 
  </div>   
`} mdxType="CodeExample" />
    <h3>{`when to use fr`}</h3>
    <p>{`Make `}<inlineCode parentName="p">{`fr`}</inlineCode>{` your default dynamic unit. Use it for tracks that should "take up the rest of the space". Use it for ratio-based layouts like letting two columns split the remaining space after a sidebar. Prefer it over the `}<inlineCode parentName="p">{`%`}</inlineCode>{` unit most of the time as `}<inlineCode parentName="p">{`fr`}</inlineCode>{` accounts for gaps' sizes (see below).`}</p>
    <h3>{`fr example`}</h3>
    <p>{`For `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` I'm using this simple grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.shell {
  display: grid;
  grid-template-columns: 80px 1fr;
}
`}</code></pre>
    <p>{`This produces a fixed `}<inlineCode parentName="p">{`80px`}</inlineCode>{` sidebar and (thanks to `}<inlineCode parentName="p">{`fr`}</inlineCode>{`) a fluid content area that occupies the rest of the space:`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/img/fr-example.jpg",
        "alt": "grid fr unit example"
      }}></img></p>
    <h2>{`percent (%)`}</h2>
    <p>{`Percent columns are based on the width of the grid container. Percent rows are based on the height of the grid container.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div> 
    <div class="item purple"></div> 
    <div class="item purple"></div> 
  </div>   
`} mdxType="CodeExample" />
    <h3>{`what's wrong with % tracks?`}</h3>
    <p>{`Percentages are based on the grid container size, so they don't care what else is going on in the grid. Watch how adding a small gap causes our grid container to overflow:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div> 
    <div class="item purple"></div> 
    <div class="item purple"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`Whereas `}<inlineCode parentName="p">{`fr`}</inlineCode>{` is a fraction of the free space (after gaps etc). Here's this same layout but with `}<inlineCode parentName="p">{`fr`}</inlineCode>{` tracks instead of `}<inlineCode parentName="p">{`%`}</inlineCode>{`:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div> 
    <div class="item purple"></div> 
    <div class="item purple"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`Look Ma, no overflow scrollbars!`}</p>
    <h3>{`when to use %`}</h3>
    <p>{`So while `}<inlineCode parentName="p">{`fr`}</inlineCode>{` is preferrable much of the time, there are certain cases where `}<inlineCode parentName="p">{`%`}</inlineCode>{` makes sense. Use `}<inlineCode parentName="p">{`%`}</inlineCode>{` when you want to guarantee that a track be a certain percentage of the grid container, no matter what. Just don't make `}<em parentName="p">{`all`}</em>{` your tracks `}<inlineCode parentName="p">{`%`}</inlineCode>{`, or you won't be able to take advantage of gaps (without causing overflow like we've seen). `}<inlineCode parentName="p">{`%`}</inlineCode>{` can also make for good gap sizes, where `}<inlineCode parentName="p">{`fr`}</inlineCode>{` cannot be used for gaps.`}</p>
    <h2>{`px`}</h2>
    <p>{`Ahh the good ol' pixel. It's a single dot of your users' conceptual screen resolution. `}<inlineCode parentName="p">{`px`}</inlineCode>{` (fixed) are often combined with `}<inlineCode parentName="p">{`fr`}</inlineCode>{` (dynamic) tracks to create fluid layouts.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 5%;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <h3>{`when to use px`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`px`}</inlineCode>{` whenever you need a fixed size track, such as a menu sidebar. Just be aware that if you use `}<inlineCode parentName="p">{`px`}</inlineCode>{` for `}<em parentName="p">{`all`}</em>{` your tracks, your layout won't be very responsive.`}</p>
    <h3>{`px example`}</h3>
    <p>{`In the `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` conversation screen I used this grid:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  .techpanel {
    display: grid;
    grid-template-rows: 40px 1fr 40px;
    grid-template-columns: 1fr;
  }
`}</code></pre>
    <p>{`to layout this futuristic chat panel:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/px-example.jpg",
        "alt": "px unit in a CSS Grid"
      }}></img></p>
    <h2>{`ch`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`ch`}</inlineCode>{` unit equates to the width of the number `}<em parentName="p">{`0`}</em>{` (zero) of the grid container's current font. It will be bigger or smaller depending on the font family used. While this sounds like a wacky unit of measurement, it's actually quite useful. `}</p>
    <p>{`I'll take the borders off this example so you can see how the `}<inlineCode parentName="p">{`1ch`}</inlineCode>{` column is the same width as the number 0 inside it:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
    .item {
      border: none;
      font-size: inherit;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: 1ch 1fr;
    grid-column-gap: 1ch;
    font-size: 75px;
  }
`} html={`
  <div class="container">
    <div class="item purple">0</div>
    <div class="item purple"><-- a zero</div>
  </div>
`} mdxType="CodeExample" />
    <p>{`Try bumping this example's `}<inlineCode parentName="p">{`font-size`}</inlineCode>{` to `}<inlineCode parentName="p">{`150px`}</inlineCode>{` and watch the `}<inlineCode parentName="p">{`0`}</inlineCode>{`, the gap, and the column all grow wider.`}</p>
    <h3>{`when to use ch`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`ch`}</inlineCode>{` anytime you want a track or gap whose size depends on the font-size. It can be useful for large magazine-style typograhic titles. `}</p>
    <p>{`It can also be used to limit the width columns of text, making them more readable. `}</p>
    <h3>{`ch example`}</h3>
    <p>{`The text you're reading right now is inside of a grid column that's limited to `}<inlineCode parentName="p">{`57ch`}</inlineCode>{` wide. You can read more about that technique in `}<a parentName="p" {...{
        "href": "/post/article-grid-layout/"
      }}>{`Article Layout with CSS Grid`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/article-grid-layout.jpg",
        "alt": "CSS grid ch unit example"
      }}></img></p>
    <h2>{`ex`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`ex`}</inlineCode>{` unit is just like `}<inlineCode parentName="p">{`ch`}</inlineCode>{` except it's based on the `}<em parentName="p">{`height`}</em>{` of the current font's lower case `}<inlineCode parentName="p">{`x`}</inlineCode>{`. In my 15 years of web dev I haven't yet had a need for this one. If you've found a good use case for it let me know and I'll add it here.`}</p>
    <h2>{`auto`}</h2>
    <p>{`Auto simply means the size of the grid items' content. Setting a column to size `}<inlineCode parentName="p">{`auto`}</inlineCode>{` means to set it to its items' width. Setting a row to `}<inlineCode parentName="p">{`auto`}</inlineCode>{` means to set it to its item's height.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 200px;
    }
    .item {
      border: none;
      font-size: 15px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;
  }
`} html={`
  <div class="container">
    <div class="item purple">It's just words, Detective. Nouns, adjectives. That just happen to be in a dope order.</div>
    <div class="item purple">YOLO</div>
    <div></div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`Try this. Change the example above's text by selecting it with your devtools. Now switch to the devtools console and type `}<inlineCode parentName="p">{`document.designMode = "on"`}</inlineCode>{` then hit enter. Now click anywhere in the text and type whatever. You'll see the grid column size change to match the width of the text. Neat!`}</p>
    <p>{`One thing to note about `}<inlineCode parentName="p">{`auto`}</inlineCode>{` columns is that they're lower in priority than other track options. A column set to `}<inlineCode parentName="p">{`auto`}</inlineCode>{` will only be its full text size if there's room. Otherwise it will cause the text to wrap to multiple lines. Make your browser window narrower to see that effect.`}</p>
    <p>{`There are a bunch of other details with `}<inlineCode parentName="p">{`auto`}</inlineCode>{` tracks like how extra space is distributed, that we can't cover here but that you can master in the `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` game.`}</p>
    <h3>{`when to use auto`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`auto`}</inlineCode>{` when you want to make your grid track match the size of content inside of it. This works for text, images, SVG, canvas elements, etc.`}</p>
    <h3>{`auto example`}</h3>
    <p>{`On the `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`grid critters landing page`}</a>{` I used `}<inlineCode parentName="p">{`auto`}</inlineCode>{` like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  .site {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 2rem;
    justify-content: center;    
  }
`}</code></pre>
    <p>{`This simple centered grid holds these fun chapter cards:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/columns-example.jpg",
        "alt": "grid-template-columns auto"
      }}></img></p>
    <p>{`BTW fun borders like this are easy to make with `}<a parentName="p" {...{
        "href": "/post/dynamic-svg-components/"
      }}>{`dynamic SVG`}</a>{`.`}</p>
    <p>{`These chapter cards vary in size depending on screen size, and my grid columns always match their width thanks to `}<inlineCode parentName="p">{`auto`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`auto`}</inlineCode>{` also happens to be the default track setting for all `}<em parentName="p">{`implicit`}</em>{` grid tracks (tracks that appear automatically whenever there's not room in a grid for all the content it holds). So if you've ever had stuff in a grid and didn't specify any row or column sizes, then you were using `}<inlineCode parentName="p">{`auto`}</inlineCode>{` without knowing it!`}</p>
    <h2>{`none`}</h2>
    <p>{`When a column or row is set to `}<inlineCode parentName="p">{`none`}</inlineCode>{` it means no `}<em parentName="p">{`explicit`}</em>{` tracks. You'll still get `}<em parentName="p">{`implicit`}</em>{` tracks if there's any grid items in the track.`}</p>
    <p>{`Here's a grid that uses `}<inlineCode parentName="p">{`none`}</inlineCode>{` to declare no explicit rows, but since there are four items in the grid we get two implicit rows showing up. We can style implicit rows using the `}<inlineCode parentName="p">{`grid-auto-rows`}</inlineCode>{` property.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 200px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
    grid-auto-rows: 1fr;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <h2>{`when to use none`}</h2>
    <p><inlineCode parentName="p">{`none`}</inlineCode>{` is the default for rows and columns if you don't specify them. Use `}<inlineCode parentName="p">{`none`}</inlineCode>{` mainly when you want to reset/override a grid that was defined earlier, for example in a media query breakpoint. It's not used very often but is still good to understand.`}</p>
    <h2>{`min-content`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`min-content`}</inlineCode>{` option tells the track to take up as little space as possible while still avoiding overflow for any of the grid items in that row or column.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 400px;
    }
    .item {
      border: none;
      font-size: 15px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }\n
  .item {
    /* you'll never believe what happens next... */
    /*white-space: nowrap; */
  }
`} html={`
  <div class="container">
    <div class="item purple">It's just words, Detective. Nouns, adjectives. That just happen to be in a dope order.</div>
    <div class="item purple">item 2</div>
    <div class="item purple">item 3 with some text in it</div>
    <div class="item purple">item 4</div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`The `}<inlineCode parentName="p">{`min-content`}</inlineCode>{` column is nice and narrow, and causes the text to wrap as much as possible. Comment in the `}<inlineCode parentName="p">{`white-space: nowrap;`}</inlineCode>{` rule to force the text to not wrap, and watch that column widen automatically. Like Granny always said, `}<em parentName="p">{`If there's one thing a Grid hates it's overflow!`}</em></p>
    <h3>{`when to use min-content`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`min-content`}</inlineCode>{` mainly when you want to instruct a track to cause any text inside it to wrap. It can also be a useful value when paired with `}<inlineCode parentName="p">{`minmax()`}</inlineCode>{`.`}</p>
    <h2>{`max-content`}</h2>
    <p><inlineCode parentName="p">{`max-content`}</inlineCode>{` is just like `}<inlineCode parentName="p">{`min-content`}</inlineCode>{` except it instructs the track to take up as much space as possible. It's almost like the track puts `}<inlineCode parentName="p">{`white-space: nowrap;`}</inlineCode>{` on all of its items.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 400px;
    }
    .item {
      border: none;
      font-size: 15px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 20px;
  }
`} html={`
  <div class="container">
    <div class="item purple">It's just words, Detective. Nouns, adjectives. That just happen to be in a dope order.</div>
    <div class="item purple">item 2</div>
    <div class="item purple">item 3 with some text in it</div>
    <div class="item purple">item 4</div>
  </div>   
`} mdxType="CodeExample" />
    <h3>{`when to use max-content`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`max-content`}</inlineCode>{` when you don't want a track's contents to wrap, or when you want the size of a certain piece of content (text/image/etc) to determine the size of the track.`}</p>
    <h2>{`rem`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`rem`}</inlineCode>{` unit is one of my favorite options. It sizes a track based on the font size of your page's root element (usually the body). So if your document has `}<inlineCode parentName="p">{`font-size: 16px`}</inlineCode>{` then `}<inlineCode parentName="p">{`1rem`}</inlineCode>{` will be equal to `}<inlineCode parentName="p">{`16px`}</inlineCode>{`. And it will scale up or down any time that `}<inlineCode parentName="p">{`font-size`}</inlineCode>{` changes.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
  .item {
    height: 300px;
    font-size: inherit;
  }
  `} showCSS={`
  :root {
    font-size: 30px;
  }\n
  .container {
    display: grid;
    grid-template-columns: 10rem 20rem;
    grid-column-gap: 0.5rem;
  }
`} html={`
  <div class="container">
    <div class="item purple">Life is for</div>
    <div class="item purple">Livin'</div>  
  </div>   
`} mdxType="CodeExample" />
    <p>{`Play with the `}<inlineCode parentName="p">{`font-size`}</inlineCode>{` above and watch the text, gap, and grid columns all adjust to match!`}</p>
    <h3>{`when to use rem`}</h3>
    <p><inlineCode parentName="p">{`rem`}</inlineCode>{` is gold for creating responsive layouts, where you almost always increase the font size as the user's device gets wider. I use `}<inlineCode parentName="p">{`rem`}</inlineCode>{` all the time especially for grid gaps, margin and padding in the grid items themselves.`}</p>
    <h3>{`rem example`}</h3>
    <p>{`For my games' new chapter screens I'm using `}<inlineCode parentName="p">{`grid-row-gap: 0.5rem;`}</inlineCode>{` to add just a touch of space between the rows:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/rem-gaps.jpg",
        "alt": "grid gaps with rem unit"
      }}></img></p>
    <h2>{`em`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`em`}</inlineCode>{` unit is just like `}<inlineCode parentName="p">{`rem`}</inlineCode>{` except it calculates its size based on the grid container's parent's `}<inlineCode parentName="p">{`font-size`}</inlineCode>{`, rather than based on the root of the document. I basically haven't used `}<inlineCode parentName="p">{`em`}</inlineCode>{` since that the (superior IMO) `}<inlineCode parentName="p">{`rem`}</inlineCode>{` unit landed in my goody bag.`}</p>
    <h2>{`vh, vw, vmin, vmax (viewport units)`}</h2>
    <p>{`While percentages are based on the size of the container element, viewport units are based on the size of the users's entire viewport. `}<inlineCode parentName="p">{`100vw`}</inlineCode>{` is the width of their screen. `}<inlineCode parentName="p">{`100vh`}</inlineCode>{` is the height of their screen. `}<inlineCode parentName="p">{`100vmin`}</inlineCode>{` is the width or height of their screen, depending which is smaller. `}<inlineCode parentName="p">{`100vmax`}</inlineCode>{` is the width or height of their screen, depending which is larger.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: 50vw 1fr;
    grid-template-rows: 30vh 1fr;
    grid-row-gap: 5vh;
    grid-column-gap: 5vw;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div> 
    <div class="item purple"></div> 
    <div class="item purple"></div> 
  </div>   
`} mdxType="CodeExample" />
    <p>{`This is example is running in an iframe, so the viewport units are relative to the iframe size. Still, you can see the tracks and gaps adjust as you make your browser window smaller.`}</p>
    <h3>{`when to use viewport units`}</h3>
    <p>{`Use viewport units when you want tracks and gaps to base thier size on the users's screen size. These units are excellent for responsive layouts.`}</p>
    <h3>{`viewport unit example`}</h3>
    <p>{`In the Exercise screen of `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` I used two `}<inlineCode parentName="p">{`47.5vh`}</inlineCode>{` rows to make the grid stretch out vertically, giving enough space for both the conversation panel and the code panel:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/viewport-tracks.jpg",
        "alt": "css grid vh viewport rows"
      }}></img></p>
    <h2>{`minmax(min, max)`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` option lets you create tracks & gaps with flexible size `}<em parentName="p">{`ranges`}</em>{`.`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: minmax(auto, 1fr) 1fr;
  }
`} html={`
  <div class="container">
    <div class="item purple">Live like Larry</div>
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`This left column is set to `}<em parentName="p">{`at least`}</em>{` the size of its text content, and `}<em parentName="p">{`at most`}</em>{` an equal half of the grid's width.`}</p>
    <h3>{`when to use minmax`}</h3>
    <p><inlineCode parentName="p">{`minmax`}</inlineCode>{` is one of the most flexible CSS Grid options we have. It's the secret sauce for making awesome `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/tile-layouts/"
      }}>{`tile layouts`}</a>{`, `}<a parentName="p" {...{
        "href": "/post/article-grid-layout/"
      }}>{`article layouts`}</a>{`, and more. It's often good to use `}<inlineCode parentName="p">{`fr`}</inlineCode>{` units as the `}<em parentName="p">{`max`}</em>{` value to make sure your grid doesn't overflow.`}</p>
    <h3>{`minmax example`}</h3>
    <p>{`This article you're reading right now uses three `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` columns:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  .article {
    display: grid;
    grid-template-columns: 
      minmax(1.2rem,1fr) minmax(auto,57ch) minmax(1.2rem,1fr);
  }
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/minmax-option.jpg",
        "alt": "CSS grid minmax column"
      }}></img></p>
    <h2>{`repeat`}</h2>
    <p>{`If your Grid has a lot of rows or columns it can be tedious to declare them all. The `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` option is a shortcut for defining the same sized track(s).`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <p>{`The `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` option also lets you define a `}<strong parentName="p">{`pattern of tracks`}</strong>{` that get repeated:`}</p>
    <CodeExample theme="grid" live={true} fixedCSS={`
    .container {
      height: 300px;
    }
  `} showCSS={`
  .container {
    display: grid;
    grid-template-columns: repeat(6, 1fr 3fr);
    grid-column-gap: 10px;
  }
`} html={`
  <div class="container">
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
    <div class="item purple"></div>
  </div>   
`} mdxType="CodeExample" />
    <h3>{`when to use repeat`}</h3>
    <p>{`Use `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` as a shortcut when you've got a lot of rows or columns. Use it for pattern grids. It's also very good for generative/computed layouts.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` when you know how much content you'll have in the grid. If you don't know, then you might be better off using `}<em parentName="p">{`implicit tracks`}</em>{` (and potentially setting your grid to grow horizontally with `}<inlineCode parentName="p">{`grid-auto-flow: column;`}</inlineCode>{`)`}</p>
    <p>{`Note that `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` can complicate your `}<a parentName="p" {...{
        "href": "/post/naming-css-grid-lines/"
      }}>{`named grid lines`}</a>{` but as long as you understand the formula for them you'll be fine.`}</p>
    <h3>{`repeat example`}</h3>
    <p>{`I often used CSS Grid to create goal trackers for myself, and `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` definitely comes in handy there. Say you have a goal of drinking a respectable 20 cups of eggnog this season. You can lay down 20 `}<inlineCode parentName="p">{`auto`}</inlineCode>{` columns with `}<inlineCode parentName="p">{`repeat`}</inlineCode>{` in no time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  .goal {
    display: grid;
    grid-template-columns: repeat(20, auto);
    grid-column-gap: 3px;
    justify-content: start;
  }
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/nog-goal.jpg",
        "alt": "nog goal"
      }}></img></p>
    <p>{`Here's this `}<a parentName="p" {...{
        "href": "https://codepen.io/geddski/pen/oNgXmWv"
      }}>{`nog goal css`}</a>{` if you actually want to use it :)`}</p>
    <h2>{`Others`}</h2>
    <p>{`There are a handful more units/options out there (e.g. `}<inlineCode parentName="p">{`cm`}</inlineCode>{`, `}<inlineCode parentName="p">{`in`}</inlineCode>{`, `}<inlineCode parentName="p">{`pc`}</inlineCode>{`, `}<inlineCode parentName="p">{`pt`}</inlineCode>{`) but they're either not widely used or don't have great browser support yet (e.g. `}<inlineCode parentName="p">{`fit-content`}</inlineCode>{`).`}</p>
    <h2>{`You've got options`}</h2>
    <p>{`The more Grid layouts you create the more you'll get comfortable using these various units/options. If you want to become an expert at all of this and stop having to constantly look things up then `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` is for you.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      